import "./crm-popup.scss";

class crmPopup {
    constructor(){
        this.popup = document.getElementById("crm-popup");
        this.popupCall = document.getElementsByClassName("crm-popup-call");
        this.body = document.body;

        this.initCallPopupClick();
        this.onDocumentKeyUp();
        this.onClickOutside();
        this.openPopupByHash();
    }

    openPopupByHash() {
        if (window.location.hash === '#request') {
            this.togglePopup();
        }
    }

    onDocumentKeyUp(){
        document.addEventListener("keyup", (e) => {
            if (e.which === 27 && this.popup.classList.contains("crm-popup_open")) this.togglePopup();
        });
    }

    /**
     * close popup on click outside popup
     */
    onClickOutside(){
        document.addEventListener("click", (e) => {
            const $target = e.target;
            if (( ($target.classList.contains("crm-popup__close") || $target.classList.contains("crm-popup"))
                && (!$target.classList.contains("crm-popup__wrapper *") || !$target.classList.contains("crm-popup__wrapper")))
                && this.popup.classList.contains("crm-popup_open")){
                e.preventDefault();
                this.togglePopup();
            }
        });
    }

    /**
     * open popup event on click link
     */
    initCallPopupClick(){
        Object.keys(this.popupCall).map(index => {
            this.popupCall[index].addEventListener("click", (e) => {
                e.preventDefault();
                this.togglePopup();
            });
        });
    }

    /**
     * toggle open | close popup
     */
    togglePopup(){
        if (this.popup.classList.contains("crm-popup_open")){
            this.popup.classList.remove("crm-popup_open");
            this.body.classList.remove("scrolling-disabled");
        } else {
            this.popup.classList.add("crm-popup_open");
            this.body.classList.add("scrolling-disabled");
        }
    }
}

export default new crmPopup();
