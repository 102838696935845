import emailjs from "emailjs-com";
import "./request-form.scss";

class RequestForm {

    constructor() {

        this.form = document.getElementById("request-form");
        this.button = this.form.querySelector("button");
        this.statusSuccess= this.form.querySelector(".request-form__status_success");
        this.statusError= this.form.querySelector(".request-form__status_error");

        emailjs.init("B7SiJfNyhWNL0B-wh");
        this.onSubmit();

    }

    onSubmit() {
        this.form.addEventListener("submit", (event) => {

            event.preventDefault();

            this.setSending();
            this.form.contact_number.value = Math.random() * 100000 | 0;

            emailjs.sendForm("service_i4thf1o", "template_9ruj4lw", this.form)
                .then(() => {
                    this.setSuccess();
                }, () => {
                    this.setError();
                });

        });
    }

    setSending() {
        this.button.innerText = "Sending...";
    }

    setSuccess() {
        this.statusSuccess.classList.add("request-form__status_show");
        this.statusError.classList.remove("request-form__status_show");
        this.button.innerText = "Send";

        // Google analytics event
        window.gtag('event', 'form', {
            'event_category' : 'send',
        });

        // Yandex metrics event
        window.ym(61548412,'reachGoal','form_send')
    }

    setError() {
        this.statusError.classList.add("request-form__status_show");
        this.statusSuccess.classList.remove("request-form__status_show");
        this.button.innerText = "Send";
    }

}

new RequestForm();
