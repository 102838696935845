import ScrollTo from "scroll-to";
import OnScreen from "onscreen";
import Hamburger from"../hamburger";
import "./navigation.scss";

class Navigation {

    constructor() {

        this.html = document.querySelector("html");
        this.body = document.querySelector("body");
        this.nav = document.getElementById("navigation");
        this.list = this.nav.querySelector(".navigation__list");
        this.links = this.nav.querySelectorAll(".navigation__link");

        this.onNavInitialezed = new Event("onNavInitialized");

        // Библиотека, которая отслеживает нахождение DOM элемента в видимости viewport.
        this.onscreen = new OnScreen({
            tolerance: window.innerHeight - 200,
            container: window,
            debounce: 10
        });

        this.onHamburgerActive();
        this.onHamburgerNotActive();
        this.onClickOutside();
        this.onClickLinks();
        this.onScreenSection();

    }

    /**
     * Отслеживает нахождение секции страницы в области вьюпорта.
     * Если секция прокручивается больше половины высоты экрана,
     * то активируются соответствующие ссылки и устанавливается хэш.
     */
    onScreenSection() {

        this.onscreen.on("enter", ".js-section", (section) => {

            const id = section.getAttribute("id");
            const link = this.nav.querySelector(`[href="#${id}"]`);

            this.setActiveLink(link);
            this.setHash(`#${id}`);

        });

        setTimeout(() => {
            document.dispatchEvent(this.onNavInitialezed);
        }, 100);

    }

    /**
     * Отслеживает клики по пунктам навигации.
     * Прокручивает страницу к соответствующей секции.
     */
    onClickLinks() {
        this.links.forEach((item) => {
            item.addEventListener("click", (event) => {

                event.preventDefault();

                const id = event.target.getAttribute("href").replace("#","");
                const section = document.getElementById(id);

                this.setActiveLink(event.target);

                ScrollTo(0, section.offsetTop, {
                    ease: "in-out-quad",
                    duration: 600
                });

            });
        });
    }

    /**
     * Отслеживает активацию бургера и запускает открытие навигации.
     */
    onHamburgerActive() {
        document.addEventListener("onHamburgerActive", () => {
            this.openNav();
        });
    }

    /**
     * Отслеживает деактивацию бургера и запускает закрытие навигации.
     */
    onHamburgerNotActive() {
        document.addEventListener("onHamburgerNotActive", () => {
            this.closeNav();
        });
    }

    /**
     * Отслеживает клик вне навигации на мобильных устройствах.
     * Если клик был вне навигации, то вызывает ее закрытие.
     */
    onClickOutside() {

        document.addEventListener("click", (event) => {

            let isNavigation = Boolean(event.target.closest("#navigation"));
            let isHamburger = Boolean(event.target.closest("#hamburger"));
            let isOutside = !isNavigation && !isHamburger;

            if (isOutside) {
                this.closeNav();
                Hamburger.setNotActive();
            }

        });

    }

    /**
     * Открывает мобильную навигацию.
     */
    openNav() {
        this.nav.classList.add("navigation_open");
        this.body.classList.add("scrolling-disable");
    }

    /**
     * Закрывает мобильную навигацию.
     */
    closeNav() {
        this.nav.classList.remove("navigation_open");
        this.body.classList.remove("scrolling-disable");
    }

    /**
     * Устанавливает активную ссылку в навигации.
     * @param link — dom элемент активной ссылки.
     */
    setActiveLink(link) {

        this.links.forEach((link) => {
            link.classList.remove("active");
        });

        link.classList.add("active");

    }

    /**
     * Устанавливает хэш якоря без инициализации прокручивания.
     * @param hash — строка вида '#about'.
     */
    setHash(hash) {

        // Для первого экрана не устанавливаем якорь, чтобы был чистый домен viewlab.dev
        if (hash === "#about") {
            let noHashUrl = window.location.href.replace(/#.*$/, "");
            window.history.replaceState("", document.title, noHashUrl);
            return false;
        }

        const id = hash.replace(/^.*#/, "");
        const elem = document.getElementById(id);

        elem.id = `${id}-tmp`;
        window.location.hash = hash;
        elem.id = id;

    }
}

export default new Navigation();
