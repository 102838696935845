class CanUseWebp{
    constructor(){
        this.$body = document.body;

        this.canUseWebP() ? this.$body.classList.add("webp") : this.$body.classList.add("no-webp");
    }

    canUseWebP() {
        const elem = document.createElement("canvas");
        if (elem.getContext && elem.getContext("2d")) {
            return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        }
        return false;
    }
}

export default new CanUseWebp();
