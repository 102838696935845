import "./screen.scss";

class Screen {

    constructor(target) {

        this.slider = target;
        this.list = this.slider.querySelector(".cards-list");
        this.items = this.list.querySelectorAll(".cards-list__item");
        this.active = false;

        this.sliderHeight = window.innerHeight + this.items[0].clientWidth * this.items.length;
        this.sliderHeightMediaDif = (window.innerWidth < 768) ? window.innerWidth / 2 : 0;

        this.initialize();

    }

    initialize() {

        this.onNavInitialized();
        this.onWindowScroll();
        this.onWindowResize();

    }

    onNavInitialized() {
        document.addEventListener("onNavInitialized", () => {
            this.setSliderSize();
            this.setListPosition();
        });
    }

    onWindowScroll() {
        window.addEventListener("scroll", () => {
            this.setListPosition();
        });
    }

    onWindowResize() {
        window.addEventListener("resize", () => {
            this.setSliderSize();
            this.setListPosition();
        });
    }

    isOnScreen(top, height) {
        return top <= 0 && top >= height * -1;
    }

    setSliderSize() {
        this.sliderHeightMediaDif = (window.innerWidth < 768) ? window.innerWidth / 2 : 0;
        this.sliderHeight = window.innerHeight + this.items[0].clientWidth * this.items.length - this.sliderHeightMediaDif;
        this.slider.setAttribute("style", `height: ${this.sliderHeight}px`);
    }

    setSliderActive(status){
        if (status === true && !this.active){
            this.active = true;
            this.slider.classList.add("screen_slider_active");
        } else if (status === false && this.active){
            this.active = false;
            this.slider.classList.remove("screen_slider_active");
        }
    }

    setListPosition() {
        const { top, height } = this.slider.getBoundingClientRect();

        this.setSliderActive(this.isOnScreen(top, height));

        if (this.isOnScreen(top, height) === false) {
            this.setTranslate3d();
            return;
        }

        const maxHeight = height * -1 - window.innerHeight * -1;
        const x = top >= maxHeight ? top : maxHeight;

        this.setTranslate3d(x);
    }

    setTranslate3d(x = 0, y = 0, z = 0) {
        this.list.setAttribute("style", `transform: translate3d(${x}px, ${y}px, ${z}px)`);
    }

}

const sliders = document.querySelectorAll(".screen_slider");

sliders.forEach(slider => {
    new Screen(slider);
});
