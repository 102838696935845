import "../../libs/hamburger";

class Hamburger {

    constructor() {

        this.hamburger = document.getElementById("hamburger");
        this.isActive = false;

        this.onHamburgerActive = new Event("onHamburgerActive");
        this.onHumburgerNotActive = new Event("onHamburgerNotActive");

        this.clickBurger();

    }

    clickBurger() {

        this.hamburger.addEventListener("click", () => {

            if (this.isActive) {
                this.setNotActive();
            } else {
                this.setActive();
            }

        });

    }

    setActive() {
        this.hamburger.classList.add("is-active");
        this.isActive = true;
        document.dispatchEvent(this.onHamburgerActive);
    }

    setNotActive() {
        this.hamburger.classList.remove("is-active");
        this.isActive = false;
        document.dispatchEvent(this.onHumburgerNotActive);
    }
}

export default new Hamburger();
